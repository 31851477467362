<template>
    <div class="tips">
        <div v-for="(item, index) in tips" :key="index">
            <div>
                <template v-if="item.txt">
                    <span class="required">*</span>
                    <span class="flex1">{{item.txt}} </span> 
                </template>   
                <template v-if="item.html">
                    <span>*</span>
                    <span v-html="item.html"></span>
                </template>      
            </div>
        </div>         
    </div>   
</template>
<script>
    export default{
        name: 'tips',
        props: ['tips']
    }
</script>
<style scoped lang="scss">
.tips{
    font-size: 0.12rem;
    margin: 0.1rem .25rem 0 .25rem;
    color: #90939A;
    * {
        text-align: left;
    }
    .required {
        color: red;
        margin-right: 0.05rem;
    }
}  
</style>